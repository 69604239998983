import { useEffect, useState } from 'react';
import axios from 'axios';
import Admin from './Admin';

const Admins = () => {
    const [admins, setAdmins] = useState([]);

    useEffect(()=>{
        loadAdmins();
    },[]);

    const loadAdmins = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/user/system-admins`);

            if(res.status !== +process.env.REACT_APP_RECORD_NOT_FOUND){
                const { data } = res.data;

                if(Array.isArray(data)){
                    setAdmins(data);
                }
            }

        }catch(err){
            console.error(err);
        }
    }

    return (
        <>
            {
                admins.length > 0 && 
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                            <p className="fw-medium text-uppercase text-primary mb-2">Our Team</p>
                            <h1 className="display-5 mb-5">Dedicated Team Members</h1>
                        </div>
                        <div className="row g-4">
                            {
                                admins.map((admin)=>{
                                    return <Admin key={admin.id} user={admin} numAdmins={admins.length} />
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        
        </>
    );
}

export default Admins;