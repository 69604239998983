import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { slider } from '../../redux/sliderSlice';
import { useSelector } from 'react-redux';

const Carousel = () => {
    const dispatch = useDispatch();

    const [sliders, setSliders] = useState(
        useSelector((state)=> state.sliders)
    );

    useEffect(()=>{
        const loadSliders = async () => {
            try{
                const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/slider?active=true`);
    
                if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
                    // alert('No record found')
                }else{
                    const { error, data } = res.data;
                    
                    if(!error && Array.isArray(data)){
                        setSliders(data);
                        dispatch(slider(data));
                    }
                }
            }catch(err){
                console.error(err);
            }
        }

        loadSliders();
    },[dispatch]);

    return (
        <div className="container-fluid px-0 mb-5">
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {
                        sliders.map((item, i)=> {
                            return (
                            <div key={item.id} className={i<1 ? 'carousel-item active' : 'carousel-item'}>
                                <img 
                                    className="w-100" 
                                    src={`${item.rootImagePath}${item.defaultImage}`}
                                    alt=""
                                    onError={(e) => {
                                        e.target.src = "/assets/img/epe.png"; 
                                        e.target.onError = null;
                                    }} 
                                 />
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10 text-start">
                                                <p className="fs-5 fw-medium text-primary text-uppercase animated slideInRight">{item.subTitle}</p>
                                                <h1 className="display-1 text-white mb-5 animated slideInRight">{item.title}</h1>
                                                {/* <Link to="#/" className="btn btn-primary py-3 px-5 animated slideInRight">Explore More</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            )                                                      
                        })
                    }

                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        
    );
}

export default Carousel;