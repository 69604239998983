import {useEffect, useState} from 'react';
import axios from 'axios';
import Why from './Why';

const WhyUs = () => {
    const [whyUs, setWhyUs] = useState([]);

    useEffect(()=>{
        loadWhyUs();
    },[]);

    const loadWhyUs = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/why-us?active=true&page=1&size=3`);

            if(res.status !== +process.env.REACT_APP_RECORD_NOT_FOUND){
                const { data } = res.data;

                if(Array.isArray(data)){
                    setWhyUs(data);
                }
            }

        }catch(err){
            console.error(err);
        }
    }

    return (
        <>
            {
                whyUs.length > 0 && 
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="position-relative me-lg-4">
                                    <img className="img-fluid w-100" src="/assets/img/realtors-600X700.jpg" alt="" />
                                    <span className="position-absolute top-50 start-100 translate-middle bg-white rounded-circle d-none d-lg-block" style={{width: 120, height: 120}}></span>
                                    {/* <button type="button" className="btn-play" data-bs-toggle="modal"
                                        data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                                        <span></span>
                                    </button> */}
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <p className="fw-medium text-uppercase text-primary mb-2">Why Choosing Us!</p>
                                <h1 className="display-5 mb-4">Few Reasons Why People Choosing Us!</h1>
                                <p className="mb-4">Here are some of the reasons why people prefer us over the years</p>
                                <div className="row gy-4">
                                    {
                                        whyUs.map((why)=>{
                                            return <Why key={why.id} why={why} />
                                        })
                                    }                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }        
        </>
    );
}

export default WhyUs;