import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import contactUsReducer from './contactUsSlice';
import userReducer from './userSlice';
import sliderReducer from './sliderSlice';

const persistConfig = {
  key: 'root', 
  storage,
}

const rootReducer = combineReducers({
  contactUsData: contactUsReducer,
  user: userReducer,
  sliders: sliderReducer
});
 
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware()
    .concat([thunk])
})

export const persistor = persistStore(store)