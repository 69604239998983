const BankAccounts = ({bankAccounts}) => {
    return (
        <div className="row container mx-auto">
            <div className="col-12">
                <div className="card recent-sales"> 
                    <div className="card-body">
                        <h5 className="card-title">Bank <span>| Accounts ({bankAccounts.length})</span></h5>

                        <div className="col-12" style={{overflowX: 'scroll'}} >
                            {
                                bankAccounts.length > 0 ?
                                <table className="table table-borderless datatable">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Account Name</th>
                                            <th scope="col">Account Number</th>
                                            <th scope="col">Currency</th>
                                            <th scope="col">Bank</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bankAccounts.map((account, i)=>{
                                                return <tr key={i}>
                                                    <th key={account.id} scope="row">{i+1}</th>
                                                    <td>{account.accountName}</td>
                                                    <td> {account.accountNumber} </td>
                                                    <td> {account?.currency?.name} </td>
                                                    <td> {account.bank.name} </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            : 
                            <div>No bank account found</div>
                            }
                        </div>
                    </div>
                </div>                
            </div>
        </div>        
    );
}

export default BankAccounts;