import axios from "axios";
import { useState } from "react";

const ResendOTP = ({email, setMessage}) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const changePasswordRoute = 'change-password';

    const sendOTP = async () => {
        try{
            setIsProcessing(true);
            const res = await axios.post(`${process.env.REACT_APP_API_PATH}auth/forgot-password`,{
                email,
                changePasswordRoute
            });
            setIsProcessing(false);
            const { message } = res.data;
            setMessage(message);

        }catch(err){
            console.error(err);
            setIsProcessing(false);
        }
    }

    return (
        <a href="#/" onClick={()=> sendOTP() }>{ !isProcessing ? 'Resend OTP' : 'Resending OTP...' }</a>
    );
}

export default ResendOTP;