import About from "../about/About";
import Carousel from "../carousel/Carousel";
import Facts from "../facts/Facts";
import Features from "../whyUs/WhyUs";
import Register from "../register/Register";
import Services from "../services/Services";
import TeamMembers from "../admins/Admins";
import Testimonies from "../testimonies/Testimonies";
import VideoPlayer from "../videoPlayer/VideoPlayer";
import Clients from "../clients/Clients";
  
const Home = () => {
    return (
        <div className="container-fluid p-0">
                <Carousel />
                <Register />  
                <About />                
                <Features />
                <VideoPlayer /> 
                <Services />                                
                <Facts />
                <Testimonies />  
                <Clients />
                <TeamMembers />                                                                                            
        </div>
    );
}

export default Home;