const Fact = ({fact}) => {
    return (
        <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.7s">
            <div className="text-center border p-5">
                <i className="fa fa-check-double fa-3x text-white mb-3"></i>
                <h1 className="display-2 text-primary mb-0" data-toggle="counter-up">{fact.value}</h1>
                <span className="fs-5 fw-semi-bold text-white">{fact.name}</span>
            </div>
        </div>
    );
}

export default Fact;