import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import Spinner from "../reUsable/Spinner";
import WhatsappUs from "../contactUs/WhatsappUs";


const AccountActivation = () => {
    const { emailVerificationCode, userSlug } = useParams();
    const [message, setMessage] = useState('');
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(emailVerificationCode && userSlug){
            const activateAccount = async () => {
                try{
                    setLoading(true);
                    const res = await axios.post(`${process.env.REACT_APP_API_PATH}auth/activate`,{
                        code: emailVerificationCode,
                        userSlug
                    });
                    setLoading(false);
        
                    if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
                        toast.error('No record found');
                    }else{
                        const { error, message, user } = res.data;
                        setMessage(message);
                        toast.success(message);
        
                        if(!error && typeof user === 'object' && user.hasOwnProperty('slug')){
                            setUser(user);
                            // we are to redirect to profile
                        }
                    }
                }catch(err){
                    setLoading(false);
                    console.error(err);
                    const message = err?.response?.data?.message || err.message;
                    setMessage(message);
                }
            }
            activateAccount();
        }
    },[emailVerificationCode, userSlug]);

    return (
        <div className="container-xxl py-5">
            
        <div className="container">
            <div className="row g-5">
            { 
                loading ? 
                    <Spinner /> 
                :
                <>
                    {
                        user && 
                            <div className="col-lg-6">
                                <div className="row gx-3 h-100 text-center">
                                    <div className="col-6 mx-auto align-self-start wow fadeInUp text-center" data-wow-delay="0.1s">
                                        <img className="img-fluid" src={`/assets/img/activated.jpg`} alt="" />
                                    </div>
                                </div>
                            </div>
                        }                

                        {
                            message && 
                            <div className="col-lg-6 wow fadeIn text-center" data-wow-delay="0.5s">
                                <p className="fw-medium text-uppercase text-primary mb-2">Account Activation</p>
                                {
                                    user && 
                                    <h1 className="display-5 mb-4">{user.name}</h1>
                                }
                                <p className="mb-4">{message}</p>            
                            </div>
                        }
                </>                  
            }

            </div>
            {
                user && 
                <WhatsappUs />
            }
        </div>
    </div>
    );
}

export default AccountActivation;