import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone'
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ProfileOverview from '../profile/ProfileOverview';
import Spinner from '../reUsable/Spinner';
import Astericks from '../reUsable/Astericks';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  export default function Register(){
    const { userSlug } = useParams();
    const canRegisterWithoutReferrer = userSlug || window.location.origin+'/' === window.location.href ? false : true;
    const navigate = useNavigate();
    const [referrer, setReferrer] = useState(null);
    const [newUser, setNewUser] = useState(null);
    const [message, setMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const [selectedBankCode, setSelectedBankCode] = useState();
    const [accountName, setAccountName] = useState();
    // const [setIsAccountNameValidated] = useState(false);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [sex, setSex] = useState('');
    const [selectedCurrencySlug] = useState('ngn');
    const [registering, setRegistering] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [emailLink, setEmailLink] = useState('');

      const thumbs = acceptedFiles.map(file => (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img
              src={URL.createObjectURL(file)}
              style={img}
              onLoad={() => { URL.revokeObjectURL(file) }}
              alt=""
            />
          </div>
        </div>
      ));

    const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);

    const initialForm = {
        firstName: '',
        lastName: '',
        otherNames: '',
        email: '',
        phone: '',
        accountNo: '',
        password: ''
    };

    let [inputField, setInputField] = useState(initialForm);

    useEffect(()=>{
        loadBanks();    
        const loadReferrer = async () => {
            try{
                const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/user/${userSlug}`);
    
                if(res.status !== +process.env.REACT_APP_RECORD_NOT_FOUND){
                    const { error, data } = res.data;
    
                    if(!error && typeof data === 'object'){
                        setReferrer(data);
                    }
                }    
            }catch(err){
                console.error(err);
            }
        }
        loadReferrer()
    },[userSlug]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMessage('');
        
        setInputField((prevState)=>({
          ...prevState,
          [name]: value
        }));
    }

    const loadBanks = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/bank`);
            if(res.status !== +process.env.REACT_APP_RECORD_NOT_FOUND){
                const { error, data } = res.data;

                if(!error){
                    setBanks(data);
                }
            }
        }catch(err){
            console.error(err);
        }
    }

    const validateAccountNumber = async (accountNumber, sortCode) => {
        try{
            setLoading(true);
            const res = await axios.post(`${process.env.REACT_APP_API_PATH}public/bank-account/validate-account-number`,{
                accountNumber,
                bankCode: sortCode
            });
            setLoading(false);

            const { error, data } = res.data;

            if(!error){
                handleInputChange({target: {name: 'accountNumber', value: accountNumber}});
                setAccountName(data.account_name);
                                
                const fullName = data.account_name.split(' ');
                const lastName = fullName[0];
                const firstName = fullName[1];
                const otherNames = fullName.slice(2);

                handleInputChange({target: {name: 'firstName', value: firstName}});
                handleInputChange({target: {name: 'lastName', value: lastName}});
                handleInputChange({target: {name: 'otherNames', value: otherNames}});                
            }
            
        }catch(err){
            console.error(err);
            setLoading(false);
        }
    }

    const { firstName, lastName, otherNames, email, phone, bankCode, accountNumber, password } = inputField;

    return (    
        <>
            <div className="container-xxl pt-5">
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                        <p className="fw-medium text-uppercase text-primary mb-2">Join Our Team</p>
                        <h1 className="display-5 mb-5">Join Us Today!</h1>
                    </div>
                    <div className="container mt-3">
                    <div className="bg-white border border-top-0 row p-4">
                        <div className="col-sm-6">
                            {
                                referrer &&
                                <div>
                                    <ProfileOverview user={referrer} />    
                                    <div className="text-primary text-center">Referrer</div>                                
                                </div>                            
                            }
                        </div>
                        <div className={`media mb-4 ${referrer ? 'col-sm-6' : 'text-center'}`}>            
                            {
                                referrer || canRegisterWithoutReferrer ? 
                                <div className="bg-light p-sm-2">            
                                    <Formik
                                        initialValues={{ 
                                            firstName,
                                            lastName,
                                            otherNames,
                                            sex,
                                            email,
                                            phone,
                                            bankCode,
                                            accountNumber,
                                            password
                                        }}
                                        validationSchema={Yup.object().shape({
                                        })}
                                    onSubmit={async (
                                        _values,
                                        // { 
                                        //     setErrors, 
                                        //     setStatus, 
                                        //     setSubmitting 
                                        // }
                                    ) => {
                                        const formData = new FormData();
                                        if(acceptedFiles.length > 0){
                                            formData.append('image', acceptedFiles[0], acceptedFiles[0]?.name); 
                                        }                                

                                        formData.append('firstName', firstName); 
                                        formData.append('lastName', lastName); 
                                        formData.append('otherNames', otherNames); 
                                        formData.append('sex', sex); 
                                        formData.append('email', email); 
                                        formData.append('phone', phone); 
                                        formData.append('bankCode', selectedBankCode); 
                                        formData.append('accountNumber', accountNumber); 
                                        formData.append('accountName', accountName); 
                                        formData.append('currencySlug', selectedCurrencySlug);                                 
                                        formData.append('password', password); 
                                        formData.append('channel', 'web'); 
                                        formData.append('isSocialUser', false); 
                                        formData.append('roleSlug', 'general'); 
                                        formData.append('referrerSlug', referrer ? referrer.slug : ''); 
                                        formData.append('channel', 'web'); 
                    
                                        try {
                                            setRegistering(true);
                                            setMessage('');

                                            const res = await axios.post(`${process.env.REACT_APP_API_PATH}auth/register`,
                                            formData
                                            );
                                            setRegistering(false);

                                            if(Array.isArray(res.data) && typeof res.data[0] === 'object'){
                                                setMessage(res.data[0].msg);
                                                return;
                                            }

                                            const { error, message, user } = res.data;                                    

                                            if(!error){
                                                // toast.success(message);
                                                setInputField(initialForm); 
                                                let emLink = '';
                                                if(email.includes('@gmail.com')){
                                                    emLink = 'https://mail.google.com/?';
                                                }else if(email.includes('@yahoo.com')){
                                                    emLink = 'https://mail.yahoo.com/';
                                                }
                                                setMessage(message);
                                                setEmailLink(emLink);                                                
                                                setNewUser(user);
                                                setShowModal(true);
                                                return;
                                            }

                                            toast.error(message);
                                    
                                        } catch (err) {
                                            setRegistering(false);
                                            const message = err?.response?.data?.message || err.message;
                                            setMessage(message);
                                            toast.error(message);
                                            console.error('e', err); 
                                        }
                                    }}
                                    >
                                    {({
                                        // errors,
                                        // handleBlur,
                                        // isSubmitting,
                                        // touched,
                                        handleSubmit,
                                        // handleChange,
                                        // values
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">


                                                <div className="my-2">
                                                    <select
                                                        className="form-select"
                                                        value={selectedBankCode}
                                                        required
                                                        onChange={e => {
                                                            setSelectedBankCode(e.target.value);
                                                        }}
                                                        >
                                                        <option value="">Choose Your Bank *</option>
                                                        {
                                                            banks.map((bank)=>{
                                                                return <option key={bank.id} value={bank.sortCode}>{bank.name}</option>
                                                            })
                                                        }                                                        
                                                    </select>
                                                </div>                                               

                                                <div className="my-2">
                                                    <input 
                                                        name="accountNumber" 
                                                        className="form-control" 
                                                        placeholder="Account number *" 
                                                        value={accountNumber}    
                                                        onChange={(e)=>{
                                                            handleInputChange(e);
                                                            setAccountName('')
                                                        }}                                             
                                                        onKeyUp={e => {
                                                            if(e.target.value.length >= 10){
                                                                validateAccountNumber(e.target.value, selectedBankCode)
                                                            }
                                                        }}
                                                        required
                                                    />
                                                </div>

                                                {
                                                    accountName && 
                                                    <div className="my-2">
                                                        <input 
                                                            name="accountName" 
                                                            className="form-control" 
                                                            placeholder="Account Name *" 
                                                            value={accountName}
                                                            onChange={e => setAccountName(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                } 

                                                <div className="my-2">
                                                    <input 
                                                        name="firstName" 
                                                        className="form-control" 
                                                        placeholder="First Name *" 
                                                        value={firstName}
                                                        onChange={handleInputChange}
                                                        required 
                                                    />                        
                                                </div>

                                                <div className="my-2">
                                                    <input 
                                                        name="lastName" 
                                                        className="form-control" 
                                                        placeholder="Last Name *" 
                                                        value={lastName} 
                                                        onChange={handleInputChange}
                                                        required 
                                                    />
                                                </div>

                                                <div className="my-2">
                                                    <input 
                                                        name="otherNames" 
                                                        className="form-control" 
                                                        placeholder="Other Names" 
                                                        value={otherNames} 
                                                        onChange={handleInputChange}
                                                    />
                                                </div>

                                                <div className="my-2">
                                                    <Astericks /> &nbsp;
                                                    <label>
                                                        <input 
                                                            type="radio" 
                                                            name="sex" 
                                                            value="M" 
                                                            onChange={(e)=> setSex(e.target.value) } 
                                                        />
                                                        Male
                                                    </label>
                                                    {` `}
                                                    <label>
                                                        <input 
                                                            type="radio" 
                                                            name="sex" 
                                                            value="F"
                                                            onChange={(e)=> setSex(e.target.value) }                                                     
                                                        />
                                                        Femle
                                                    </label>
                                                </div>

                                                <div className="my-2">
                                                    <input 
                                                        name="phone" 
                                                        className="form-control" 
                                                        placeholder="Phone number *" 
                                                        value={phone} 
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <span style={{fontSize:12}} className="text-center text-danger">You may receive a bonus on this phone number when you verify your account</span>
                                                </div>

                                                <div className="my-2">
                                                    <input 
                                                        name="email" 
                                                        type="email"
                                                        className="form-control" 
                                                        placeholder="Email *" 
                                                        value={email}
                                                        onChange={handleInputChange} 
                                                        required
                                                    />
                                                </div>

                                                <div className="my-2">
                                                    <input 
                                                        name="password" 
                                                        className="form-control" 
                                                        placeholder="Password *" 
                                                        value={password}
                                                        type="password"
                                                        onChange={handleInputChange} 
                                                        required
                                                    />
                                                </div>                                                                                                           
      
                                                <div className='border my-2'>                                            
                                                    {/* <Dropzone 
                                                        onDrop={(acceptedFiles)=>{
                                                            setAcceptedFiles(acceptedFiles);
                                                        }
                                                        // multiple = false
                                                        }>
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                            <div {...getRootProps()} className='text-center' style={{height: 100, border: '5px solid white'}}>
                                                                <input {...getInputProps()}  />
                                                                <p>Click or Drag and drop your picture. 400px by 400px is recommended</p>
                                                            </div>
                                                            </section>
                                                        )}
                                                    </Dropzone> */}


<Dropzone
    onDrop={(acceptedFiles) => {
        setAcceptedFiles(acceptedFiles);
    }}
>
    {({ getRootProps, getInputProps }) => (
        <section>
            <div
                {...getRootProps()}
                className='dropzone'
                style={{
                    height: 150,
                    border: '2px dashed #007BFF', // Change to a blue dashed border
                    borderRadius: '8px', // Rounded corners
                    backgroundColor: '#f9f9f9', // Light background for contrast
                    transition: 'border 0.3s ease', // Smooth transition on hover
                    padding: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer', // Pointer cursor on hover
                    position: 'relative' // For positioning the tooltip
                }}
                onMouseOver={(e) => (e.currentTarget.style.border = '2px solid #0056b3')} // Change border on hover
                onMouseOut={(e) => (e.currentTarget.style.border = '2px dashed #007BFF')} // Revert border
            >
                <input {...getInputProps()} />
                <p style={{ margin: 0, color: '#333', textAlign: 'center' }}>
                    Click or Drag and drop your picture (Recommended: 400px by 400px)
                </p>
                {/* Optional Tooltip or Info Icon */}
                <span style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    fontSize: '14px',
                    color: '#007BFF',
                    cursor: 'pointer',
                }} title="Upload your profile picture">
                    ℹ️
                </span>
            </div>
        </section>
    )}
</Dropzone>



                                                    <section>
                                                        <aside style={thumbsContainer}>
                                                            {thumbs}
                                                        </aside>
                                                        {
                                                            acceptedFiles.length > 0 && 
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm"
                                                                onClick={()=>{
                                                                    console.log(thumbs);
                                                                    setAcceptedFiles([]);
                                                                }}
                                                            >
                                                            Remove
                                                        </button>
                                                        }
                                                    </section>
                                                </div>

                                                {
                                                    loading && <Spinner />
                                                }

                                                {
                                                    message && 
                                                    <div className="text-center p-2 text-danger">{message}</div>
                                                }

                                                <div className='text-center'>
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-lg btn-primary" 
                                                    >
                                                        Register{registering && 'ing...'}
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                )}
                                    </Formik>
                                </div>
                                : 
                                <div className="h6">
                                    Approach a certified member for registration link or send an email to {appDetails.email}
                                </div>
                            }
                        </div>               
                    </div>
                </div>
                </div>
            </div>              

            {
                newUser && 
                <Modal
                    show={showModal}
                    onHide={()=>{
                        navigate(`/profile/${newUser.slug}`);
                    }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Attention</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {message}
                    </Modal.Body>
                    <Modal.Footer>
                    {
                        emailLink && 
                        <a 
                            variant="primary"
                            className="btn"
                            href={emailLink}
                            target='_blank'
                            rel="noreferrer noopener"
                        >
                            Go to Email
                        </a>
                    }
                    <Button variant="secondary" onClick={()=>{
                        setShowModal(false);
                        navigate(`/profile/${newUser.slug}`);
                    }}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}