import axios from "axios";
import { useState } from "react";
import { toast } from 'react-hot-toast';

const ResendAccountVerificationDetails = ({email, setMessage}) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const sendDetails = async () => {
        try{
            setIsProcessing(true);
            const res = await axios.post(`${process.env.REACT_APP_API_PATH}auth/resend-verification-details`,{
                email
            });
            setIsProcessing(false);
            const { message } = res.data;
            setMessage(message);
            toast.success(message);
        }catch(err){
            console.error(err);
            setIsProcessing(false);
        }
    }

    return (
        <button 
            className="btn btn-light btn-sm mx-2" 
            onClick={()=> sendDetails() }
            type="button"
        >
            { !isProcessing ? 'Resend Verification Email' : 'Resending...' }
        </button>
    );
}

export default ResendAccountVerificationDetails;