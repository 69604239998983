import { useState, useEffect } from 'react';
import axios from 'axios';
import Client from './Client';

const Clients = () => {
    const [clients, setClients] = useState([]);

    useEffect(()=>{
        loadclients();
    },[]);

    const loadclients = async () =>{
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/client?active=true&asc=false`);
            if(res.status !== +process.env.REACT_APP_RECORD_NOT_FOUND){
                const { error, data } = res.data;

                if(!error && Array.isArray(data)){
                    setClients(data);
                }
            }
        }catch(err){
            console.error(err);
        }
    }

    return (
        <>
            {
                clients.length > 0 && 
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                            <h1 className="mb-3">Products / Services</h1>
                            {/* <p>We are good at what we do and we give our best always</p> */}
                        </div>
                        <div className="row g-4">
                            {
                                clients.map(client =>{
                                    return <Client key={client.id} client={client} />
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        
        </>
    );
}

export default Clients;