import * as Yup from 'yup';
import { Formik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const [message, setMessage] = useState('');
    const changePasswordRoute = 'change-password';

    const initialForm = {
        email: ''
    };

    let [inputField, setInputField] = useState(initialForm);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMessage('');
        
        setInputField((prevState)=>({
          ...prevState,
          [name]: value
        }));
    }

    const { email } = inputField;

    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">          
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <h5 className="card-title text-center pb-0 fs-4">Forgotten Password to Your Account?</h5>
                                        <p className="text-center small">Enter your email</p>
                                    </div>
                                    <Formik
                                initialValues={{ 
                                    email
                               }}
                                validationSchema={Yup.object().shape({})}
                            onSubmit={async (
                                _values,
                                // { 
                                //     setErrors, 
                                //     setStatus, 
                                //     setSubmitting
                                //  }
                            ) => {                                                                       
                                try {
                                    setIsProcessing(true);

                                    const res = await axios.post(`${process.env.REACT_APP_API_PATH}auth/forgot-password`,{
                                        email,
                                        changePasswordRoute
                                    });

                                    setIsProcessing(false);

                                    const { error, message } = res.data;

                                    if(!error){    
                                        toast.success(message);
                                        setMessage(message);
                                        navigate(`/${changePasswordRoute}`)
                                    }else{
                                        toast.error(message);
                                    }                                    
                            
                                    } catch (err) {
                                        setIsProcessing(false);
                                        const message = err?.response?.data?.message || err.message;
                                        setMessage(message);
                                        console.error('e', err); 
                                    }
                            }}
                            >
                            {({
                                errors,
                                handleBlur,
                                isSubmitting,
                                touched,
                                handleSubmit,
                                handleChange,
                                values
                            }) => (
                                <form onSubmit={handleSubmit} className="row g-3 needs-validation" noValidate>
                                    <div className="col-12">
                                        <label htmlFor="email" className="form-label">email</label>
                                        <div className="input-group has-validation">
                                            <input 
                                                name="email" 
                                                className="form-control" 
                                                id="email" 
                                                value={email}
                                                onChange={handleInputChange}
                                                required 
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <p 
                                            className="small mb-0"
                                        >
                                            <Link to="/login">Remembered your password</Link>
                                        </p>
                                    </div>

                                    {
                                        message && 
                                        <div className="text-center">
                                            {message}
                                        </div>
                                    }

                                    <div className="col-12 text-center">
                                        <button disabled ={isProcessing} className="btn btn-primary" type="submit">{ !isProcessing ? 'ForgotPassword' : 'Please wait...' }</button>
                                    </div>
                                </form>
                            )}
                            </Formik>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ForgotPassword;