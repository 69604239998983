const ContactUsSuccess = () => {
    const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);

    return <div className="p-3 pt-5 col-sm-9 mx-auto text-center row">
        <div className="col-sm-6 pt-3">
            Thanks for reaching out. We'll get in touch with you as soon as possible. Keep an eye on your email
        </div>
        <div className="col-sm-6">
            <div className='my-3'>
                <img src="/assets/img/customer_service.jpg" alt="Customer Care" className="img-fluid" />
            </div>

            <div>
                <h3>You can also send us a mail: </h3> 
                    <div><strong>{appDetails.email}</strong></div>                    
            </div>                    
        </div>
    </div>
}

export default ContactUsSuccess;