import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons' 

library.add(faFacebookF, faTwitter, faLinkedin, faInstagram);

const TopBar = () => {
    const contactUsData = useSelector((state)=> state.contactUsData);
    const primaryContactUsData = contactUsData.filter((item)=> item.isPrimary === true)[0] || null;

    return (
        <>
            {
                primaryContactUsData && 
                <div className="container-fluid bg-dark px-0">
                    <div className="row g-0 d-none d-lg-flex">
                        <div className="col-lg-6 ps-5 text-start">
                            <div className="h-100 d-inline-flex align-items-center text-white">
                            {/* <FontAwesomeIcon icon={faCoffee} /> */}
                                <span>Follow Us:</span>
                                  {/* <FontAwesome
                                    className='super-crazy-colors'
                                    name='home'
                                    size='2x'
                                    spin
                                    style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                /> */}
                                {
                                    primaryContactUsData.facebook && 
                                    <a className="btn btn-link text-light" href={primaryContactUsData.facebook}>
                                        <FontAwesomeIcon icon={['fab', 'facebook-f']}  />
                                    </a>
                                }
                                {
                                    primaryContactUsData.twitter && 
                                    <a className="btn btn-link text-light" href={primaryContactUsData.twitter}>
                                        <FontAwesomeIcon icon={['fab', 'twitter']}  />                                        
                                    </a>
                                }
                                {
                                    primaryContactUsData.linkedIn && 
                                    <a className="btn btn-link text-light" href={primaryContactUsData.linkedIn}>
                                        <FontAwesomeIcon icon={['fab', 'linkedin']}  />
                                    </a>
                                }
                                {
                                    primaryContactUsData.instagram && 
                                    <a className="btn btn-link text-light" href={primaryContactUsData.instagram}>
                                        <FontAwesomeIcon icon={['fab', 'instagram']}  />
                                    </a>
                                }
                            </div>
                        </div>
                        {
                            primaryContactUsData?.phone && 
                            <div className="col-lg-6 text-end">
                                <div className="h-100 topbar-right d-inline-flex align-items-center text-white py-2 px-5">
                                    <span className="fs-5 fw-bold me-2"><i className="fa fa-phone-alt me-2"></i>Call Us:</span>
                                    <span className="fs-5 fw-bold">{primaryContactUsData.phone}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>        
    );
}

export default TopBar;