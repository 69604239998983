import { useSelector, useDispatch } from "react-redux";
import { Link  } from "react-router-dom";
import {user as userReducer } from "src/redux/userSlice";
import axios from 'axios';

const NavBar = () => {
    const user = useSelector((state)=> state?.user);    
    const dispatch = useDispatch();

    const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);
    const screenWidth = window.innerWidth;

    return (
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top py-0 pe-5">
            <Link to="/" className="navbar-brand ps-5 me-0">

                {
                    screenWidth < 576 ?
                        <h5 className="text-white m-0">
                            {appDetails.nameAbbr}               
                        </h5>                    
                    : 
                        // screenWidth > 1200 
                        <h3 className="text-white m-0">
                            {appDetails.name}                   
                        </h3>                                             
                }                                      
            </Link>
            <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <Link to="/about" className="nav-item nav-link">About</Link>
                    {/* <Link to="/services" className="nav-item nav-link">Services</Link> */}
                    {/* <Link to="/facts" className="nav-item nav-link">Facts</Link> */}
                    {/* <Link to="/features" className="nav-item nav-link">Features</Link> */}
                    <Link to="/team" className="nav-item nav-link">Team</Link>                    
                    <Link to="/contact" className="nav-item nav-link">Contact</Link>   
                    {
                        user?.slug ?
                        <div className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown">My Account</Link>
                            <div className="dropdown-menu bg-light m-0">                            
                                <Link to={`profile/${user.slug}`} className="dropdown-item">Profile</Link>
                                <Link to={`team-members`} className="dropdown-item">Team Members</Link>
                                <Link 
                                    to="/" 
                                    className="dropdown-item"
                                    onClick={()=>{
                                        dispatch(userReducer(null));
                                        localStorage.removeItem('accessToken');

                                        try{
                                            axios.post(`${process.env.REACT_APP_API_PATH}auth/logout`)
                                        }catch(err){
                                            console.error(err);
                                        }
                                    }}
                                >
                                    Logout
                                </Link>                                                            
                            </div>
                        </div>
                        :
                        <Link to="/login" className="nav-item nav-link">Login</Link>
                    }                                                                       
                </div>
            </div>
        </nav>
    );
}

export default NavBar;