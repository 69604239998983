import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    contactUs: (state = initialState, action) => {
      return [
        ...action.payload
      ]
    },
  },
})

export const { contactUs } = contactUsSlice.actions;

export default contactUsSlice.reducer;