import * as Yup from 'yup';
import { Formik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { user } from '../../redux/userSlice';
import { toast } from 'react-hot-toast';
import ResendAccountVerificationDetails from './ResendAccountVerificationDetails';

export default function Login (){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isProcessing, setIsProcessing] = useState(false);
    const [message, setMessage] = useState('');
    const [isPasswordType, setIsPasswordType] = useState(true);
    const [verificationEmail, setVerificationEmail] = useState('');
    const [showResendVerificationDetails, setShowResendVerificationDetails] = useState(false);

    const initialForm = {
        username: '',
        password: ''
    };

    let [inputField, setInputField] = useState(initialForm);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMessage('');
        
        setInputField((prevState)=>({
          ...prevState,
          [name]: value
        }));
    }


    const { username, password } = inputField;

    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">          
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                        <p className="text-center small">Enter your email & password to login</p>
                                    </div>
                                    <Formik
                                        initialValues={{ 
                                                username,
                                                password
                                        }}
                                            validationSchema={Yup.object().shape({})}
                                        onSubmit={async (
                                            _values,
                                            // { 
                                            //     setErrors, setStatus, setSubmitting 
                                            // }
                                        ) => {                                                                       
                                        try {
                                        setIsProcessing(true);

                                        const res = await axios.post(`${process.env.REACT_APP_API_PATH}auth/login`,{
                                            email: username,
                                            password
                                        });

                                        setIsProcessing(false);

                                        const { error, message, data, messageCode } = res.data;

                                        if(!error && typeof data === 'object' && data.accessToken){
                                            localStorage.setItem('accessToken', data.accessToken)
                                            const u = jwtDecode(data.accessToken);
                                            dispatch(user(u));
                                            setInputField(initialForm); 
                                            navigate(`/profile/${u.slug}`)
                                        }else{
                                            toast.error(message);
                                            setMessage(message);                                        

                                            if(messageCode === 'UNVERIFIED_ACCOUNT'){
                                                setVerificationEmail(username);
                                                setShowResendVerificationDetails(true);
                                            }
                                        }                                    
                                
                                            } catch (err) {
                                                setIsProcessing(false);
                                                const message = err?.response?.data?.message || err.message;
                                                setMessage(message);
                                                console.error('e', err); 
                                            }
                                        }}
                                        >
                                        {({
                                            errors,
                                            handleBlur,
                                            isSubmitting,
                                            touched,
                                            handleSubmit,
                                            handleChange,
                                            values
                                        }) => (
                                            <form onSubmit={handleSubmit} className="row g-3 needs-validation" noValidate>
                                                <div className="col-12">
                                                    <label htmlFor="yourUsername" className="form-label">Username</label>
                                                    <div className="input-group has-validation">
                                                        <input 
                                                            name="username" 
                                                            className="form-control" 
                                                            id="yourUsername" 
                                                            value={username}
                                                            onChange={handleInputChange}
                                                            required 
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <label htmlFor="yourPassword" className="form-label">Password</label>
                                                    <input 
                                                        type={isPasswordType ? 'password' : 'text'} 
                                                        name="password" 
                                                        className="form-control" 
                                                        id="yourPassword" 
                                                        required 
                                                        value={password}
                                                        onChange={handleInputChange}
                                                    />
                                                    <div className="invalid-feedback">Please enter your password!</div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-check">
                                                        <input 
                                                            onClick={()=> setIsPasswordType(!isPasswordType) }
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            name="remember" 
                                                            value="true" 
                                                            id="rememberMe" 
                                                        />
                                                        <label className="form-check-label" htmlFor="rememberMe">Show Password</label>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <p 
                                                        className="small mb-0"
                                                    >
                                                        <Link to="/forgot-password">Forgot Password</Link>
                                                    </p>
                                                </div>

                                                {
                                                    message && 
                                                    <div className="text-center">
                                                        {message}
                                                    </div>
                                                }

                                                {
                                                    showResendVerificationDetails &&
                                                    <div className="col-12">
                                                        <p 
                                                            className="small mb-0"
                                                        >
                                                            Didn't get the Email? 
                                                            <ResendAccountVerificationDetails 
                                                                email={verificationEmail} 
                                                                setMessage={setMessage} 
                                                            />
                                                        </p>
                                                    </div>

                                                }                                    

                                                <div className="col-12 row">
                                                    <button 
                                                        disabled ={isProcessing} 
                                                        className="btn btn-primary my-2" 
                                                        type="submit"                                            
                                                    >
                                                        { !isProcessing ? 'Login' : 'Please wait...' }
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </div>
    );
}