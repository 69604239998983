import { createSlice } from '@reduxjs/toolkit'
const initialState = null;
  
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    user: (state = initialState, action) => {
      return {
        ...action.payload
      }
    },
  },
})

export const { user } = userSlice.actions

export default userSlice.reducer