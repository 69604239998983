import { useState, useEffect}  from 'react';
import axios from 'axios';
import Fact from './Fact';

const Facts = () => {
    const [facts, setFacts] = useState([]);

    useEffect(()=>{
        loadFacts();
    },[]);

    const loadFacts = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/fact`);

            if(res.status !== +process.env.REACT_APP_RECORD_NOT_FOUND){
                const { error, data } = res.data;

                if(!error && Array.isArray(data)){
                    setFacts(data);
                }
            }
        }catch(err){
            console.error(err);
        }
    }

    return (
        <>
            {
                facts.length > 0 && 
                <div className="container-fluid facts my-5 p-5">
                    <div className="row g-5">
                        {
                            facts.map((fact)=> {
                                return <Fact key={fact.id} fact={fact} />
                            })
                        }                        
                    </div>
                </div>
            }
        </>
    );
}

export default Facts;