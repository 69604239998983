import * as Yup from 'yup';
import { Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import ResendOTP from './ResendOTP';
import { toast } from 'react-hot-toast';

const ResetPassword = () => {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const [message, setMessage] = useState('');
    const [isUrlOTP] = useState(searchParams.get("c") ? true : false);
    
    const initialForm = {
        otp: searchParams.get("c") || '',
        email: searchParams.get("e") || '',
        newPassword: '',
        confirmNewPassword: ''
    };
    
    let [inputField, setInputField] = useState(initialForm);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMessage('');
        
        setInputField((prevState)=>({
          ...prevState,
          [name]: value
        }));
    }

    const { otp, email, newPassword, confirmNewPassword } = inputField;
    
    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">          
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <h5 className="card-title text-center pb-0 fs-4">Reset your password</h5>
                                    </div>
                                    <Formik
                                        initialValues={{ 
                                            email,
                                            newPassword,
                                            confirmNewPassword
                                        }}
                                            validationSchema={Yup.object().shape({})}
                                        onSubmit={async (
                                            _values,
                                            // { 
                                            //     setErrors, 
                                            //     setStatus, 
                                            //     setSubmitting
                                            //  }
                                        ) => {           
                                            
                                            if(newPassword !== confirmNewPassword){
                                                toast.error('Password mismatch');
                                                setMessage('Password mismatch');
                                                return;
                                            }
                                            
                                            try {
                                                setIsProcessing(true);
                                                setMessage('');

                                                const res = await axios.post(`${process.env.REACT_APP_API_PATH}auth/change-password`,{
                                                    email,
                                                    code: otp,
                                                    newPassword
                                                });

                                                setIsProcessing(false);

                                                const { error, message } = res.data;

                                                if(Array.isArray(res.data) && typeof res.data[0] === 'object'){
                                                    setMessage(res.data[0].msg);
                                                    return;
                                                }

                                                if(!error){                                                    
                                                    setMessage(message);
                                                    toast.success(message);
                                                    navigate(`/login`);
                                                }else{
                                                    toast.error(message);
                                                }                                    
                                        
                                                } catch (err) {
                                                    setIsProcessing(false);
                                                    const message = err?.response?.data?.message || err.message;
                                                    setMessage(message);
                                                }
                                            }}
                                        >
                                        {({
                                            errors,
                                            handleBlur,
                                            isSubmitting,
                                            touched,
                                            handleSubmit,
                                            handleChange,
                                            values
                                        }) => (
                                            <form onSubmit={handleSubmit} className="row g-3 needs-validation" noValidate>
                                                {
                                                    !isUrlOTP && 
                                                    <div className="col-12">
                                                        <label htmlFor="otp" className="form-label">OTP</label>
                                                        <div className="input-group has-validation">
                                                            <input 
                                                                name="otp" 
                                                                className="form-control" 
                                                                id="otp" 
                                                                value={otp}
                                                                onChange={handleInputChange}
                                                                required 
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="col-12">
                                                    <label htmlFor="newPassword" className="form-label">New password</label>
                                                    <div className="input-group has-validation">
                                                        <input 
                                                            type="password"
                                                            name="newPassword" 
                                                            className="form-control" 
                                                            id="newPassword" 
                                                            value={newPassword}
                                                            onChange={handleInputChange}
                                                            required 
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <label htmlFor="confirmNewPassword" className="form-label">New password again</label>
                                                    <div className="input-group has-validation">
                                                        <input 
                                                            type="password"
                                                            name="confirmNewPassword" 
                                                            className="form-control" 
                                                            id="confirmNewPassword" 
                                                            value={confirmNewPassword}
                                                            onChange={handleInputChange}
                                                            required 
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <p 
                                                        className="small mb-0"
                                                    >
                                                        Didn't get the OTP? <ResendOTP email={email} setMessage={setMessage} />
                                                    </p>
                                                </div>

                                                {
                                                    message && 
                                                    <div className="text-center">
                                                        {message}
                                                    </div>
                                                }

                                                <div className="col-12 text-center">
                                                    <button 
                                                        disabled ={isProcessing} 
                                                        className="btn btn-primary" 
                                                        type="submit"
                                                    >
                                                        { !isProcessing ? 'Change Password' : 'Please wait...' }
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ResetPassword;