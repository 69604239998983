
const ProfileOverview = ({user}) => {
    return (
        <div className="text-center">
            <div>
                <img 
                    src={ `${user.rootImagePath}${user.defaultImage}`} 
                    alt="Profile" 
                    className="rounded-circle img-fluid" 
                    onError={(e) => {
                        e.target.src = `${process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE_PATH}`; 
                        e.target.onError = null;
                    }}                 
                />
            </div>
            <div>
                <h3>{user.name}</h3>                
            </div>
        </div>
    );
}

export default ProfileOverview;