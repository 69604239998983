import { Link } from "react-router-dom";

const CopyRight = () => {
    const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);
    return (
        <div className="container-fluid copyright bg-dark py-4">
        <div className="container text-center">
            <p className="mb-2">
                Copyright &copy; &nbsp; 
                <Link className="fw-semi-bold" to="/">
                    {appDetails.name}
                </Link>, 
                All Right Reserved.
            </p>
            <p className="mb-0">
                Designed By &nbsp;
                <a className="fw-semi-bold" href="https://greatftech.com" target="_blank" rel="noreferrer noopener" >Great FTech</a>
            </p>
        </div>
    </div>
    );
}

export default CopyRight;