// import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const Spinner = ({message = '', color = "#ffffff", size = 150 }) => {
  
    return (
        <span className="sweet-loading text-center">
            <ClipLoader
                color={color}
                loading={true}
                cssOverride={override}
                size={size}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            {
                message
            }
        </span>
    );
}

export default Spinner;