import { useState, useEffect } from 'react';
import axios from 'axios';
import Service from './Service';

const Services = () => {
    const [services, setServices] = useState([]);

    useEffect(()=>{
        loadServices();
    },[]);

    const loadServices = async () =>{
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/service?active=true&asc=false`);
            if(res.status !== +process.env.REACT_APP_RECORD_NOT_FOUND){
                const { error, data } = res.data;

                if(!error && Array.isArray(data)){
                    setServices(data);
                }
            }
        }catch(err){
            console.error(err);
        }
    }

    return (
        <>
            {
                services.length > 0 && 
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                            <h1 className="mb-3">Our Services</h1>
                            <p>We are good at what we do and we give our best always</p>
                        </div>
                        <div className="row g-4">
                            {
                                services.map(service =>{
                                    return <Service key={service.id} service={service} />
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        
        </>
    );
}

export default Services;