import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from "react-router-dom";
import axios from 'axios';
import { persistor, store } from './redux/store';
import './polyfill'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { user as userSlice} from './redux/userSlice';


// request interceptor
// axios.interceptors.request.use( request => {
//   const accessToken = localStorage.getItem('accessToken');

//   if(accessToken){
//       request.headers['Authorization'] = `Bearer ${accessToken}`;
//   }
  
//   return request;
// },error => {
//   return Promise.reject(error);
// });


// Request Interceptor
axios.interceptors.request.use((request)=>{
  const accessToken = localStorage.getItem('accessToken');

  if(accessToken){
      request.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return request;
});

// Response interceptor
const {dispatch} = store;
axios.interceptors.response.use((response)=>{
  const backEndErrorCode = response.data.code;
  if(backEndErrorCode === 'TOKEN_ERROR'){
    localStorage.removeItem('accessToken');
    dispatch(userSlice(null));
  }

  return response;
});



ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
