import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Why = ({why}) => {
    return (
        <div className="col-12">
            <div className="d-flex">
                <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                    <i className="fa fa-check text-white"></i>
                    <FontAwesomeIcon 
                        icon={faCheck} 
                        className='text-white' 
                    />
                </div>
                <div className="ms-4">
                    <h4>{why.name}</h4>
                    <span>{why.shortDescription}</span>
                </div>
            </div>
        </div>
    );
}

export default Why;