import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { faEnvelope,faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const About = () => {
    const [aboutUs, setAbout] = useState(null);
    const contactUsData = useSelector((state)=> state.contactUsData);
    const primaryContactUsData = contactUsData.filter((item)=> item.isPrimary === true)[0] || null;
    
    useEffect(()=>{
        loadAboutUs();
    },[]);

    const loadAboutUs = async () => {
        try{
          const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/about-us`);
    
          if(res.status !== process.env.REACT_APP_RECORD_NOT_FOUND){
            const { error, data } = res.data;
    
            if(!error && typeof data === 'object'){
              setAbout(data[0]);
            }    
          }
        }catch(err){
          console.error(err);
        }
      }

      let imageClassName = "col-6 align-self-start wow fadeInUp ";
      imageClassName+= aboutUs && aboutUs?.image.length < 1 ? 'mx-auto' : '';
      
    return (
        <>
            {
                aboutUs && typeof aboutUs === 'object' && 
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="row gx-3 h-100">
                                    <div className={`${imageClassName}`} data-wow-delay="0.1s">
                                        <img className="img-fluid" src={`${aboutUs.rootImagePath}${aboutUs.defaultImage}`} alt="" />
                                    </div>
                                    {
                                        aboutUs.image.length > 0 && 
                                        <div className="col-6 align-self-end wow fadeInDown" data-wow-delay="0.1s">
                                            <img className="img-fluid" src={`${aboutUs.image[0].rootImagePath}${aboutUs.image[0].defaultImage}`} alt="" />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <p className="fw-medium text-uppercase text-primary mb-2">About Us</p>
                                <h1 className="display-5 mb-4">{aboutUs.title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: aboutUs.content }} />

                                {
                                    primaryContactUsData && 

                                    <div className="row pt-2">
                                        <div className="col-12">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                                    <FontAwesomeIcon 
                                                        icon={faEnvelope} 
                                                        className='text-white' 
                                                    />
                                                </div>
                                                <div className="ms-3">
                                                    <p className="mb-2">Email us</p>
                                                    {
                                                        primaryContactUsData.email.length > 20 ?
                                                        <h6 className="mb-0">{primaryContactUsData.email}</h6>
                                                        : 
                                                        <h5 className="mb-0">{primaryContactUsData.email}</h5>
                                                    }                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                                    <FontAwesomeIcon 
                                                        icon={faPhone} 
                                                        className='text-white' 
                                                    />                                                
                                                </div>
                                                <div className="ms-3">
                                                    <p className="mb-2">Call us</p>
                                                    {
                                                        primaryContactUsData.email.length > 20 ?
                                                        <h6 className="mb-0">{primaryContactUsData.phone}</h6>
                                                        : 
                                                        <h5 className="mb-0">{primaryContactUsData.phone}</h5>                                                        
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default About;