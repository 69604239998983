import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { faPhone, faEnvelope, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons' 

library.add(faFacebookF, faTwitter, faLinkedin, faInstagram, faYoutube);

const Footer = () => {
    const contactUsData = useSelector((state)=> state.contactUsData);

    return (
        <div className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">            
            <div className="row g-5">
                {
                    contactUsData.length > 0 && 
                    <div className="col-lg-4 col-md-6">
                        {
                            contactUsData[0].address && 
                            <>
                                <h5 className="text-white mb-4">Office Address</h5>
                                <p className="mb-2">
                                    <FontAwesomeIcon 
                                        icon={faMapMarker} 
                                        className='text-white' 
                                    />
                                    &nbsp;&nbsp;
                                    {contactUsData[0].address}
                                </p>                            
                            </>
                        }

                        {
                            contactUsData[0].phone &&
                            <p className="mb-2">
                                <FontAwesomeIcon 
                                    icon={faPhone} 
                                    className='text-white' 
                                />
                                &nbsp;&nbsp;
                                {contactUsData[0].phone}
                            </p>
                        }

                        {
                            contactUsData[0].email && 
                            <p className="mb-2">                                
                                <FontAwesomeIcon 
                                    icon={faEnvelope} 
                                    className='text-white' 
                                />
                                &nbsp;&nbsp;
                                {contactUsData[0].email}
                            </p>
                        }

                        <div className="d-flex pt-3">

                            {
                                contactUsData[0].twitter && 
                                <a 
                                    className="btn btn-square btn-primary rounded-circle me-2" 
                                    href={contactUsData[0].twitter}
                                    target="_blank" rel="noreferrer noopener"
                                >                                    
                                <FontAwesomeIcon 
                                    icon={['fab', 'twitter']}  
                                />                                

                                </a>
                            }

                            {
                                contactUsData[0].facebook && 
                                <a 
                                    className="btn btn-square btn-primary rounded-circle me-2" 
                                    href={contactUsData[0].facebook}
                                    target="_blank" rel="noreferrer noopener"
                                >
                                <FontAwesomeIcon 
                                    icon={['fab', 'facebook-f']}  
                                />   

                                </a>
                            }

                            {
                                contactUsData[0].youtube &&
                                <a 
                                    className="btn btn-square btn-primary rounded-circle me-2" 
                                    href={contactUsData[0].youtube}
                                    target="_blank" rel="noreferrer noopener"
                                >
                                <FontAwesomeIcon 
                                    icon={['fab', 'youtube']}  
                                />   
                                </a>
                            }

                            {
                                contactUsData[0].linkedIn && 
                                <a 
                                    className="btn btn-square btn-primary rounded-circle me-2" 
                                    href={contactUsData[0].linkedIn}
                                    target="_blank" rel="noreferrer noopener"
                                >
                                    <FontAwesomeIcon 
                                        icon={['fab', 'linkedin']}  
                                    />   
                                </a>
                            }
                        </div>
                    </div>
                }

                <div className="col-lg-4 col-md-6">
                    <h5 className="text-white mb-4">Quick Links</h5>
                    {/* <FontAwesome name='arrow-right' /> */}
                    <Link className="btn btn-link" to="/about">About Us</Link>
                    <Link className="btn btn-link" to="/contact">Contact Us</Link>
                    <Link className="btn btn-link" to="/services">Our Services</Link>
                    <Link className="btn btn-link" to="#/">Terms & Condition</Link>
                    <Link className="btn btn-link" to="#/">Support</Link>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h5 className="text-white mb-4">Business Hours</h5>
                    <p className="mb-1">Monday - Friday</p>
                    <h6 className="text-light">09:00 am - 07:00 pm</h6>
                    <p className="mb-1">Saturday</p>
                    <h6 className="text-light">09:00 am - 12:00 pm</h6>
                    <p className="mb-1">Sunday</p>
                    <h6 className="text-light">Closed</h6>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Footer;