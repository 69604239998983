export default function WhatsappUs(){
    return <div className="p-3 pt-5 col-sm-9 mx-auto text-center row">
        <div className="text-center">
            <a 
                href={process.env.REACT_APP_WHATSAPP_GROUP_LINK}
                target="_blank"
                rel="noreferrer noopener"
            >
            <div className='my-3'>
                <img src="/assets/img/logos/whatsappLogo.png" alt="" className="img-fluid" />
            </div>

            <div>
                <h3>Join the team on Whatsapp </h3> 
                Join Now                               
            </div>    
            </a>                   
        </div>
    </div>
}