import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import ProfileOverview from "./ProfileOverview";
import BankAccounts from "./BankAccounts";
import WhatsappUs from "../contactUs/WhatsappUs";

const Profile = () => {
    const [user, setUser] = useState(null);
    const { userSlug } = useParams();

    useEffect(()=>{
        const loadUser = async () => {
            try{
                const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/user/${userSlug}`);
    
                if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
                    // alert('No record found')
                }else{
                    const { error, message, data } = res.data;
    
                    if(!error && typeof data === 'object'){
                        setUser(data);
                    }else{
                        toast.error(message);
                    }
                }
    
            }catch(err){
                console.error(err);
            }
        }
        loadUser();
    },[userSlug]);

    return (
        <div>
            {
                user && 
                <>
                    <ProfileOverview user={user} />
                    <div className="text-center">
                        <strong>Referral Link:</strong> {` `}
                        <div>
                            <Link to={`${user.link}`}>
                                {user.link}
                            </Link>
                        </div>
                    </div>

                    <BankAccounts bankAccounts={user.bankAccounts} />

                    <WhatsappUs />
                
                </>
            }
        </div>
    );
}

export default Profile;