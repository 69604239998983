import { Link } from "react-router-dom";

const Service = ({service}) =>{
    return (
        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <Link className="cat-item d-block bg-light text-center rounded p-3" to="#/">
                <div className="rounded p-4">
                    <div className="icon mb-3">
                        <img 
                            className="img-fluid" 
                            src={`${service.rootImagePath}${service.defaultImage}`}
                            alt="Icon" 
                            onError={(e)=>{
                                e.target.src = `/assets/img/no-image-45X45.jpg`; 
                                e.target.onError = null;         
                            }}
                        />
                    </div>
                    <h6>{service.name}</h6>
                    <span>{service.value}</span>
                </div>
            </Link>
        </div>
    );
}

export default Service;