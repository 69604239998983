import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from '../reUsable/Spinner';
import { useSelector } from 'react-redux';

const TeamMembers = () => {
    const user = useSelector((state)=> state?.user);    
    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(()=>{
        const loadTeamMembers = async () => {
            try{
                setLoading(true);
                const res = await axios.get(`${process.env.REACT_APP_API_PATH}user/referees/${user.slug}`);
                setLoading(false);
    
                if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
                    setMessage('No record found');
                }else{
                    const { data } = res.data;
    
                    if(Array.isArray(data)){
                        setTeamMembers(data);
                    }
                }
                
            }catch(err){
                console.error(err);
                setLoading(false);
            }
        }

        loadTeamMembers();
    },[user]);


    return (
        <div className="row container mx-auto">
            <div className="col-12">
                <div className="card recent-sales"> 
                    <div className="card-body">
                        <h5 className="card-title">Team <span>| Members ({teamMembers.length})</span></h5>

                        <div className="col-12" style={{overflowX: 'scroll'}}>
                            {
                                teamMembers.length > 0 ?
                                <table className="table table-borderless datatable">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Names</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            teamMembers.map((team, i)=>{
                                                return <tr key={team.id} >
                                                    <th scope="row">{i+1}</th>
                                                    <td>
                                                        <Link to={`/profile/${team.slug}`} className="text-primary">{team.firstName} {team.lastName}</Link>
                                                    </td>
                                                    <td> {team.phone} </td>
                                                    <td> {team.email} </td>
                                                    {/* <td> {team.role} </td> */}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            : 
                            <div>{message}</div>
                            }
                        </div>
                        
                        {
                            loading && 
                            <div className="text-center">
                                <Spinner />
                            </div>
                        }
                    </div>
                </div>                
            </div>
        </div>        
    );
}

export default TeamMembers;