import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import TopBar from './content/topBar/TopBar';
import NavBar from './content/navBar/NavBar';
import Services from './content/services/Services';
import Footer from './content/footer/Footer';
import CopyRight from './content/copyright/CopyRight';
import { contactUs } from './redux/contactUsSlice';
import Home from './content/home/Home';
import Profile from './content/profile/Profile';
import NotFound from './content/error/NotFound';
import About from './content/about/About';
import Features from './content/whyUs/WhyUs';
import Facts from './content/facts/Facts';
import Login from './content/auth/Login';
import ForgotPassword from './content/auth/ForgotPassword';
import TeamMembers from './content/teamMember/TeamMembers';
import Admins from './content/admins/Admins';
import AccountActivation from './content/accountActivation/AccountActivation';
import ResetPassword from './content/auth/ResetPassword';

import { Toaster } from 'react-hot-toast';
import ContactUs from './content/contactUs/ContactUs';
import ContactUsSuccess from './content/contactUs/ContactUsSuccess';

const App = () => {
  const dispatch = useDispatch();

  useEffect(()=>{ 
    // if(!Array.isArray(contactUsData) || contactUsData.length < 1){
      // dispatch(contactUs([]));

      const loadContactUs = async () => {
        try{
          const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/contact-us`);
    
          if(res.status !== process.env.REACT_APP_RECORD_NOT_FOUND){
            const { error, data } = res.data;
    
            if(!error && Array.isArray(data)){
              dispatch(contactUs(data));
            }
    
          }
        }catch(err){
          console.error(err);
        }
      }


      loadContactUs();
    // }  
  },[dispatch]);

    return (      
      <>
          <TopBar />
          <NavBar />
              <Routes>
                    <Route
                      exact
                      path={"/"}
                      element={<Home />}
                    />

                    <Route
                      exact
                      path={"/services"}
                      element= {<Services />}
                    />

                    <Route
                      exact
                      path={"/about"}
                      element= {<About />}
                    />

                    <Route
                      exact
                      path={"/facts"}
                      element= {<Facts />}
                    />

                    {/* <Route
                      exact
                      path={"/testimonies"}
                      element= {<Testimonies />}
                    /> */}

                    <Route
                      exact
                      path={"/team-members"}
                      element= {<TeamMembers />}
                    />

                    <Route
                      exact
                      path={"/features"}
                      element= {<Features />}
                    />

                    <Route
                      exact
                      path={"/team"}
                      element= {<Admins />}
                    />

                    <Route
                      exact
                      path={"/contact"}
                      element= {<ContactUs />}
                    />


                    <Route
                      exact
                      path={"/contact/success"}
                      element= {<ContactUsSuccess />}
                    />                    

                    <Route
                      exact
                      path={"/login"}
                      element= {<Login />}
                    />

                    <Route
                      exact
                      path={"/forgot-password"}
                      element= {<ForgotPassword />}
                    />

                    <Route
                      exact
                      path={"/change-password"}
                      element= {<ResetPassword />}
                    />

                    <Route
                      exact
                      path={"/account-activation/:emailVerificationCode/:userSlug"}
                      element= {<AccountActivation />}
                    />

                    <Route
                      path={"/profile/:userSlug"}
                      element={<Profile />}
                    />
                    
                    <Route
                      path={"/join/:userSlug"}
                      element={<Home />}
                    />

                    <Route
                      path={"/am9pbg=="}
                      element={<Home />}
                    />

                    <Route 
                      path="*" 
                      name="Page 404" 
                      element={<NotFound />} 
                    />

              </Routes>                                                  
          <Footer />
          <CopyRight />  
          {/* <button onClick={notify}>Make me a toast</button> */}
          <Toaster />
      </>
    );
  }


export default App;
