import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons' 

library.add(faFacebookF, faTwitter, faLinkedin, faInstagram, faYoutube);

const TeamMember = ({user, numAdmins}) => {
    const { facebook, twitter, instagram } = user.socialMediaHandle;  
    let cName = "wow fadeInUp ";
    
    if(numAdmins >= 4){
        cName += " col-lg-3 col-md-6 ";
    }else if(numAdmins === 3){
        cName += " col-lg-4 col-md-6 ";
    }else if(numAdmins === 2){
        cName += " col-sm-6 ";
    }

    return (
        <div className={cName} data-wow-delay="0.1s">
            <div className="team-item">
                <div className="text-center">
                    <img 
                        className="img-fluid" 
                        // style={{width: 200, height: 200}}
                        src={`${user.rootImagePath}${user.defaultImage}`}
                        alt="" 
                        onError={(e) => {
                            e.target.src = `/assets/img/default_human_image_550X600.jpg`; 
                            e.target.onError = null;
                        }} 
                    />
                </div>
                <div className="d-flex">
                    <div className="flex-shrink-0 btn-square bg-primary" style={{width: 90, height: 90}}>

                        {
                            (facebook || twitter || instagram) && 
                            <FontAwesomeIcon 
                                icon={faShare} 
                                className='text-white h2' 
                            />  
                        }

                    </div>
                    <div className="position-relative overflow-hidden bg-light d-flex flex-column justify-content-center w-100 ps-4" style={{height: 90}}>
                        <h5>{user.name  }</h5>
                        <span className="text-primary">{user.officeRole}</span>
  
                        {
                            (facebook || twitter || instagram) && 
                            <div className="team-social">
                                {
                                    facebook && 
                                    <a 
                                        className="btn btn-square btn-dark rounded-circle mx-1" 
                                        href={facebook}
                                        target="_blank" rel="noreferrer noopener"
                                    >
                                        <FontAwesomeIcon icon={['fab', 'facebook-f']} className='text-white' /> 
                                    </a>
                                }
                                    
                                {
                                    twitter && 
                                    <a 
                                        className="btn btn-square btn-dark rounded-circle mx-1" 
                                        href={twitter}
                                        target="_blank" rel="noreferrer noopener"
                                    >
                                        <FontAwesomeIcon icon={['fab', 'twitter']} className='text-white' />   
                                    </a>
                                }
                                    
                                {
                                    instagram && 
                                    <a 
                                        className="btn btn-square btn-dark rounded-circle mx-1" 
                                        href={instagram}
                                        target="_blank" rel="noreferrer noopener"
                                    >
                                        <FontAwesomeIcon icon={['fab', 'instagram']} className='text-white' />
                                    </a>
                                }
                            </div> 
                        }                                                             
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamMember;